<template>
  <v-container grid-list-xl>
    <v-layout
      row
      wrap
    >
      <v-flex
        lg12
        sm12
        xs12
      >
        <Search />
        <div class="mb-3">
          <GeneratePDF
            :head="table.header"
            :itens="list"
            :title="'Pedidos'"
            :items-per-page="8"
          />
          <GenerateExcel
            :head="table.header"
            :itens="list"
            :title="'Pedidos'"
          />
        </div>
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 40, 50, 100, -1], showFirstLastPage: true, showCurrentPage: true }"
          @click:row="goToDetail"
        >
          <template v-slot:item.group="{ item }">
            <span class="text-capitalize">  {{
              item.group
            }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            {{
              item.status | statusPedido
            }}
          </template>
          <template v-slot:item.payments="{ item }">
            <span
              v-if="item.payments && item.payments.length"
            >{{ item.payments[0].status | statusPagamento }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.inspections="{ item }">
            <span
              v-if="item.inspections && item.inspections.length"
            >{{ item.inspections[item.inspections.length-1].status | statusAutovistoria }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{
              item.createdAt | date
            }}
          </template>
          <template v-slot:item.inspectionCreatedAt="{ item }">
            {{
              getInspectionDate(item)
            }}
          </template>

          <template
            v-slot:item.numeroPedidoArce="{ item }"
          >
            {{ item.numeroPedidoArce ? item.numeroPedidoArce : '-' }}
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{ name: 'dashboard-pedidos-detail', params: { id: item.id } }"
              title="Visualizar Pedido"
            >
              <v-icon>visibility</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Search from './Search';
  import GenerateExcel from '@/components/GenerateExcel.vue';
  import GeneratePDF from '@/components/GeneratePDF.vue';
  import dayjs from 'dayjs';
  import { StatusAutovistoria } from '@/models/status-autovistoria.enum';

  export default {
    components: {
      Search,
      GenerateExcel,
      GeneratePDF,
    },
    data: () => ({
      pagination: null,
      list:null
    }),
    computed: {
      ...mapState('pedido', ['table'])
    },
    watch: {
      pagination: {
        handler() {
          this.changePagination();
        },
        deep: true
      },
      'table.content': function (newVal, oldVal) {
        this.solicitationList()

      }
    },
    destroyed() {
      this.clear();
    },
    methods: {
      getInspectionDate(value) {
        if(!value.inspections.length) return '-'

        return dayjs(value.inspections[0].createdAt).format('DD/MM/YYYY')
      },
      changePagination() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

        let input = { ...this.table.paginate.input };

        if (
          this.$route.query.hasOwnProperty('status') ||
          this.$route.query.hasOwnProperty('type')
        ) {
          const { status, type } = this.$route.query;
          input = {
            ...this.table.paginate.input,
            statuses: status.split(','),
            type,
          };
        }

        if(this.$route.query.hasOwnProperty('inspection_statuses')) {
          const { inspection_statuses } = this.$route.query;
          if (inspection_statuses.length) {
            input = {
              ...this.table.paginate.input,
              inspection_statuses: inspection_statuses
            };
          }
        }

        const paginate = {
          ...this.table.paginate,
          input,
          inputPage: {
            page: page,
            size: itemsPerPage == -1 ? null : itemsPerPage,
            order:
              sortBy.length > 0
                ? sortBy[0] === 'payments'
                  ? 'payment.status'
                  : sortBy[0]
                : 'id',
            descingOrder: sortDesc.length > 0 ? sortDesc[0] : true
          }
        };

        this.changePage(paginate);
        this.search();
      },
      goToDetail({ id }) {
        this.$router.push({
          name: 'dashboard-pedidos-detail',
          params: { id }
        });
      },
      solicitationList() {
        const { content } = this.table;
        const formattedList = content.map(item => ({
          'Número': item.id,
          'Grupo': item.group,
          'Número ARCE': item.numeroPedidoArce,
          'Empresa': item.company.name,
          'Placa': item.placa,
          'Tipo': item.typeSolicitation.name,
          'Status Pedido': item.status,
          'Status Pagamento': item.payments && item.payments.length ? item.payments[0].status : '-',
          'Status Autovistoria': item.inspections && item.inspections.length ? this.getAutovistoriaStatus(item.inspections[item.inspections.length-1].status) : '-',
          'Data do Pedido': dayjs(item.createdAt).format('DD/MM/YYYY'),
          'Data da Autovistoria': this.getInspectionDate(item),
        }));

        this.list = formattedList;
      },
      getAutovistoriaStatus(value) {
        return StatusAutovistoria[value]
      },
      ...mapActions('pedido', { search: 'SEARCH' }),
      ...mapActions('pedido', { clear: 'CLEAR' }),
      ...mapActions('pedido', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
