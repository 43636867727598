<template>
  <v-form
    ref="form"
    lazy-validation
    class="app-form"
    @submit.prevent="onSubmit"
  >
    <v-expansion-panels
      v-model="panel"
      class="app-expansion-panels"
    >
      <v-expansion-panel @click.stop="changeCollapse">
        <v-expansion-panel-header class="app-expansion-panel-header mb-0">
          <h4 class="text">
            <v-icon>filter_alt</v-icon>
            <span>Filtrar Pedidos</span>
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="app-expansion-panel-content">
          <v-row class="mt-3">
            <v-col
              v-show="!isEngineer"
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-select
                v-model="table.paginate.input.type"
                :items="tipoPedido"
                item-text="name"
                item-value="id"
                :clearable="true"
                label="Escolha o tipo de pedido"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-select
                v-model="table.paginate.input.statuses"
                :items="statusPedido"
                :item-text="v => v[1]"
                :item-value="v => v[0]"
                :clearable="true"
                label="Escolha o status do pedido"
                single-line
                outlined
                dense
                multiple
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-text-field
                v-model="table.paginate.input.placa"
                placeholder="Digite a placa do veículo"
                label="Placa"
                outlined
                dense
              />
            </v-col>
            <v-col
              v-show="!isEngineer"
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-select
                v-model="table.paginate.input.company"
                :items="empresas"
                item-text="name"
                item-value="id"
                :clearable="true"
                label="Escolha uma empresa"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              v-show="!isEngineer"
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-select
                v-model="table.paginate.input.statusPayment"
                :items="statusPagamento"
                :item-text="v => v[1]"
                :item-value="v => v[0]"
                :clearable="true"
                label="Escolha o status do pagamento"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              v-show="!isEngineer"
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-text-field
                v-model="table.paginate.input.group"
                placeholder="Ex:. G-1001"
                label="Grupo"
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="3"
              :xs="12"
              class="pt-1"
            >
              <v-select
                v-model="table.paginate.input.inspection_statuses"
                multiple
                :items="statusAutovistoria"
                :item-text="v => v.name"
                :item-value="v => String(v.value)"
                :clearable="true"
                label="Escolha o status da autovistoria"
                single-line
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-dialog
                ref="dialog"
                v-model="dialog"
                :return-value.sync="dateCriacao"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="table.paginate.input.createdAt"
                    label="Data Pedido"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateCriacao"
                  scrollable
                  range
                  @input="formatCreatedAtDate"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(dateCriacao)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col
              :lg="3"
              :md="3"
              :sm="6"
              :xs="12"
              class="pt-1"
            >
              <v-dialog
                ref="dialogDataAutoVistoria"
                v-model="dialogDataAutoVistoria"
                :return-value.sync="dateAutovistoria"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="table.paginate.input.inspection_created_at"
                    label="Data Autovistoria"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="dateAutovistoria"
                  scrollable
                  range
                  @input="formatAutovistoriaCreatedAtDate"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="dialogDataAutoVistoria = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialogDataAutoVistoria.save(dateAutovistoria)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-divider />
          <div class="mt-3 d-flex">
            <v-spacer />
            <v-btn
              outlined
              color="error"
              type="button"
              class="mr-2"
              @click.prevent.stop="clearFilter"
            >
              <v-icon size="14">
                delete
              </v-icon>Limpar Filtros
            </v-btn>
            <v-btn
              depressed
              color="success"
              type="submit"
            >
              Filtrar
            </v-btn>
          </div>
          <section
            v-if="table.isLoading"
            class="loading"
          >
            <v-progress-circular
              indeterminate
              color="success"
            />
          </section>
          <div id="endForm" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex';
  import { StatusPedidoEnum } from '@/models/status.enum';
  import { StatusPagamento } from '@/models/status-pagamento.enum';

  import TipoPedidoService from '@/services/tipo-pedido.service';
  import EmpresaService from '@/services/empresa.service';
  import AutovistoriaService from '@/services/autovistoria.service';

  import dayjs from 'dayjs'

  export default {
    data: () => ({
      panel: null,
      tipoPedido: null,
      statusPedido: Object.entries(StatusPedidoEnum),
      statusPagamento: Object.entries(StatusPagamento),
      empresas: null,
      dateCriacao: null,
      isEngineer: '',
      statusAutovistoria: null,
      dialog: null,
      dialogDataAutoVistoria: null,
      dateAutovistoria: null
    }),
    computed: {
      ...mapState('pedido', ['table']),
      ...mapState('global', ['user'])
    },
    mounted() {
      this.verifyIsEngineer();
      if(!this.isEngineer) {
        this.loadTiposPedido()
        this.loadEmpresas()
      }
      this.loadAutovistoriaStatuses()
    },
    methods: {
      formatCreatedAtDate(values) {
        this.table.paginate.input.createdAt = values.map(v=> dayjs(v).format('DD/MM/YYYY'))
      },
      formatAutovistoriaCreatedAtDate(values) {
        this.table.paginate.input.inspection_created_at = values.map(v=> dayjs(v).format('DD/MM/YYYY'))
      },
      async onSubmit() {
        await this.search();
        this.$vuetify.goTo('#endForm');
      },
      clearFilter() {
        this.clear();
        this.search();
      },
      async loadTiposPedido() {
        const paginate = {
          input: {
            name: ''
          },
          inputPage: {
            page: 1,
            size: 50,
            order: 'id',
            descendingOrder: true
          }
        };
        const { items } = await TipoPedidoService.page(paginate).toPromise();
        this.tipoPedido = items;
      },
      async loadEmpresas() {
        const paginate = {
          input: {
            name: ''
          },
          inputPage: {
            page: 1,
            size: 500,
            order: 'name',
            descendingOrder: true
          }
        };
        const { items } = await EmpresaService.page(paginate).toPromise();
        this.empresas = items;
      },
      async loadAutovistoriaStatuses() {
        this.statusAutovistoria = await AutovistoriaService.find_statuses().toPromise();
      },
      verifyIsEngineer() {
        const { functionalities } = this.user;
        const verifyEngineer = functionalities.includes('inspection_review')
        this.isEngineer = verifyEngineer
      },
      changeCollapse() {},
      ...mapActions('pedido', { search: 'SEARCH' }),
      ...mapActions('pedido', { clear: 'CLEAR' }),
      ...mapActions('pedido', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
