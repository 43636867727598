<template>
  <v-btn
    class="elevation-0 mx-2"
    color="#CC3300"
    dark
    @click="gerarPDF"
  >
    <v-icon class="mr-2"> 
      picture_as_pdf 
    </v-icon>
    Gerar PDF
  </v-btn>
</template>

<script>
  import jsPDF from 'jspdf';
  import 'jspdf-autotable'
  import logoImage from '@/assets/images/logo.png';
  import { mapState } from 'vuex'
  import dayjs from 'dayjs';
  import { APP_NAME } from '@/commons/config'

  export default {
    name:'GeneratePDF',
    props:{
      head: {
        type: Array,
        default:  function () {
          return [];
        }
      },
      itens: {
        type: Array,
        default:  function () {
          return [];
        }
      },
      itemsPerPage: {type: Number, default: 10},
      title: {type: String, default: ''}
    },
    computed: {
      ...mapState({
        user: (state) => state.global.user,
      }),
    },
    methods:{
      gerarPDF() {
        const headersWithoutActions = this.head.filter(header => header.text !== 'Ações');
        const headers = headersWithoutActions.map(h => h.text);

        const generalDate = dayjs().format('DD/MM/YYYY');
        const generalHour = dayjs().format('HH:mm:ss');
        const username = this.user.name;
        
        const totalPages = Math.ceil(this.itens.length / this.itemsPerPage);

        const pdfDoc = new jsPDF({
          orientation: 'landscape',
        });

        pdfDoc.addImage(logoImage, 'PNG', 15, 12, 60, 15)
        pdfDoc.setFontSize(18);
        pdfDoc.text(this.title,145,23)

        for (let currentPage = 0; currentPage <= totalPages; currentPage++) {
          const startIndex = currentPage * this.itemsPerPage;
          const endIndex = startIndex + this.itemsPerPage;
          const dataSlice = this.itens.slice(startIndex, endIndex);

          // Adicionar uma nova página para cada iteração além da primeira
          if (currentPage > 0 && dataSlice.length > 0) {
            pdfDoc.addPage();
          }

          if (dataSlice.length > 0) {
            const config = {
              startY: 37,
              margin: { left: 10, top: 33 },
              head: [headers],
              didDrawPage: function (data) {
                if (dataSlice.length > 0) {
                  data.table.head = [];
                  data.margin = { left: 10, top: 0 };
                  pdfDoc.setFontSize(8);
                  pdfDoc.text(`Relatório foi gerado em ${generalDate} as ${generalHour}, pelo usuário ${username}, por meio do sistema ${APP_NAME}`, 150, 208);
                }
              },
              body: dataSlice.map(obj => Object.values(obj)),
            };

            pdfDoc.autoTable(config);
          }
        }

        pdfDoc.save(`${this.title} ${generalDate} - ${generalHour}.pdf`);

      },
      removeActions(headers){
        var index = headers.indexOf('Ações');
        if(index == -1) return ;
        return headers.splice(index, 1)
      }
    }
  }
</script>
