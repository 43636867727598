import { Observable } from 'rxjs';
import { AutovistoriaAPI } from '@/api/autovistoria.api';

const api = new AutovistoriaAPI();

const find_statuses = (): Observable<any> => {
  return api.statuses();
}

export default {
  find_statuses
}
