<template>
  <v-btn
    class="elevation-0 mx-2"
    color="#009900"
    dark
    @click="gerarExcel"
  >
    <v-icon class="mr-2">
      article
    </v-icon>
    Gerar Excel
  </v-btn>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import * as XLSX from 'xlsx';
  import dayjs from 'dayjs';

  export default {
    name:'GenerateExcel',
    props:{
      head: {
        type: Array,
        default: function () {
          return [];
        }
      },
      itens: {
        type: Array,
        default: function () {
          return [];
        }
      },
      title: {type: String, default: null}

    },
    computed: {
      ...mapState({
        user: (state) => state.global.user,
      }),
    },
    methods:{
      gerarExcel() {

        const generalDate = dayjs().format('DD/MM/YYYY - HH:mm:ss')
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(this.itens)

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')

        XLSX.writeFile(workbook, `${this.title} ${generalDate} .xlsx`)
      }
    },
  }

</script>
